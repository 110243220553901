<template>
    <v-card>
        <v-row no-gutters class="align-center">
            <v-col cols="12">
                <v-card-title class="flex-nowrap">
                    <router-link
                        :to="{
                            name: 'snapshot-overview',
                            params: routeParams()
                        }"
                        class="text-h6 text-decoration-none secondary--text mr-3">
                        <span>{{ item.space_long_id }}</span>
                    </router-link>
                </v-card-title>
            </v-col>
            <v-col cols="12" v-if="item.space_description">
                <v-card-subtitle class="pt-0">
                    {{ item.space_description }}
                </v-card-subtitle>
            </v-col>
            <v-col cols="12">
                <v-card-text>
                    <v-btn
                        elevation="0"
                        :to="{
                            name: 'snapshot-files',
                            params: routeParams('file')
                        }"
                        small
                        fab
                        class="mr-2">
                        <v-icon color="secondary">folder</v-icon>
                    </v-btn>
                    <v-btn
                        elevation="0"
                        :to="{
                            name: 'snapshot-tables',
                            params: routeParams(),
                            query: $route.query
                        }"
                        small
                        fab
                        class="mr-2">
                        <v-icon color="secondary">mdi-table-large</v-icon>
                    </v-btn>
                    <v-btn
                        elevation="0"
                        :to="{
                            name: 'snapshot-applications',
                            params: routeParams(),
                            query: $route.query
                        }"
                        small
                        fab>
                        <v-icon color="secondary">desktop_windows</v-icon>
                    </v-btn>
                </v-card-text>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { enumsData } from '@/mixins/enums'

export default {
    name: 'DashboardSpaceCard',
    props: ['item'],
    mixins: [enumsData],
    methods: {
        routeParams(type) {
            const param = {
                oid: this.$route.params.oid,
                sid: this.item.sid,
                iid: this.item.iid,
                snid: this.item.snid
            }
            if (type === 'file') param.fileArea = this.folderTypesLabels.WORKSPACE_FILES
            return param
        }
    }
}
</script>
